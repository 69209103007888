import Flyout from './flyout'
import LNAVBackdropController from './lnav_backdrop_controller'
import { debounce } from '../../../../lib/utils/timing'

export default (() => {
  class FlyoutController {
    constructor({ parentNode, selector = '.m-flyout' } = {}) {
      this.LNAVBackdropController = new LNAVBackdropController({ parentNode })
      this.elems = [...parentNode?.querySelectorAll(selector)]
      if (!this.elems.length) {
        return
      }
      this.flyouts = this.elems
        .map(elem => new Flyout(this, elem))
        .filter(flyout => flyout.initialized)

      this._addEventListeners()
    }

    // Public Methods
    closeAll() {
      this.flyouts.forEach(flyout => flyout.close())
      if (
        this.LNAVBackdropController?.elems?.length &&
        this.LNAVBackdropController?._handleMWebDefaulState &&
        window.innerWidth <= 900
      ) {
        this.LNAVBackdropController?._handleMWebDefaulState()
      }
    }

    // Private Methods
    _addEventListeners() {
      document.addEventListener('click', this._documentClickHandler.bind(this))
      document.addEventListener('flyout:closeAll', this.closeAll.bind(this))
      window.addEventListener('resize', debounce(this._handleResize.bind(this), 200))
    }

    _handleResize() {
      this.closeAll()
    }

    _documentClickHandler(e) {
      const { target } = e
      if (target.classList.contains('m-flyout--overlay')) {
        this.closeAll()
        return
      }
      this.flyouts.forEach(flyout => {
        const clickWithinFlyout = flyout.elem.contains(target)
        const clickFlyoutBtn = flyout.btns.some(btn => btn.contains(target))
        if (!clickWithinFlyout && !clickFlyoutBtn) {
          flyout.close()
        }
      })
    }
  }
  return FlyoutController
})()
